import {React,useState,useEffect} from 'react'
import './home.css'
import Axios from 'axios'

export default function Home() {
    const[error,setError] = useState('');
    const[name,setName] = useState('');
    const[month,setMonth] = useState('');
    const[loaded,setLoaded] = useState('false');
    const[months,setMonths] = useState([]);
    const[members,setMembers] = useState([]);
    const[all,setAll] = useState([]);
    const[success,setSuccess] = useState('');
    const[entry,setEntry]=useState('false');


    useEffect(() =>{
        Axios.get('https://masilaapi.easybox.ke/getAllFreeMembers').then((res)=>{
            setMembers(res.data)
            })
       
      },[])

      useEffect(() =>{
        Axios.get('https://masilaapi.easybox.ke/getAllFreeMonths').then((res)=>{
            setMonths(res.data)
            })
       
      },[])

      useEffect(() =>{
        Axios.get('https://masilaapi.easybox.ke/getAll').then((res)=>{
            setAll(res.data)
            })
       
      },[])

    const handleSubmit = () =>{
        if(name != '' && month != ''){
            Axios.put('https://masilaapi.easybox.ke/submitMonth',{
                name : name,
                month : month,   
            })
            window.location.reload(false);
        }
        else{
            setError('Please pick your name and month.')
        }
    }
  return (
    <div className='homeWrapper'>
        <div className="homeBody">
            <div className="homeImage">
                <img className='mimage' src="/images/masilas.jpg" alt="" />
            </div>

                        <div className="homeDetails">
                                            <h3 className='title'>Select your name and random month</h3>
                <p style={{color:'red',textAlign:'start'}}>{error}</p>
                <p>{success}</p>
                <select onChange={(e)=>{setName(e.target.value)}} className='selectName' name="" id="">
                    <option >-Your Name-</option>
                    {members.map((val) => {
                   
                   return(

                    <option value={val.name} >{val.name}</option>
                   )
               })}
              
                </select>
                <select onChange={(e)=>{setMonth(e.target.value)}} className='selectName' name="" id="">
                    <option >-Select Month-</option>
                    {months.map((val) => {
                   
                   return(

                    <option value={val.month} >Random Month</option>
                   )
               })}
                </select>
                <button className='submit' onClick={handleSubmit}>Submit</button>
                        </div>


            </div>
            <div className="results">
                <h3 className='title'>Results</h3>
                <hr />
              
                        <div>
                            {all.map((val) => {
                   
                   return(

                    <p style={{textAlign:'start'}}>{val.name} - {val.month}</p>
                   )
                            })}

                
            </div>
        </div>
        </div>
    );
 }
